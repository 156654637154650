<div class="map" itemscope itemtype="http://schema.org/Organization">
  <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa360c090775fad4c0793202925046bedd6cf9e88113f11ed5c9d7cfcf442b67b&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
  <div class="contacts" [ngClass]="{'close-map':!showMap}">
    <div class="close-btn" (click)="closeMap()">
      <span>{{showMap?'скрыть':'показать контакты'}}</span>
    </div>
    <div class="pr-3 mr-3" >
      <h3 itemprop="name">Progoff</h3>
      <h2>Наши контакты</h2>
      <h3 class="my-4"><a itemprop="telephone" href="tel:+74955077479">+7(967)065-48-54</a></h3>
      <div class="contact">
        <span>Адрес:</span>
        <p >Москва, ул. Щербаковская, 38</p>
      </div>
      <div class="contact">
          <span>Время работы:</span> 
          <p class="m-0">Вт-Пт: 09.00 - 18.00</p> 
          <p >Сб - Пн: 06.00 - 23.00</p>
      </div>
      <div class="contact">
        <span>Email:</span>
        <a href="mailto:info@progoff.ru"><p itemprop="email" style="color:black">info@progoff.ru</p></a>
      </div>
      
    </div>
    <div class="pl-3 ml-3 form" style="border-left:1px solid lightgray">
        <application-form [show]="false"></application-form>
    </div>
    
    
  </div>
</div>
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <h4><span [ngClass]="{'active':router.url=='/about'}" (click)="go('/about')">О компании</span></h4>
        <h5><span [ngClass]="{'active':router.url=='/about/advantages'}" (click)="go('/about/advantages')">Приемущества</span></h5>
        <h5><span [ngClass]="{'active':router.url=='/about'}" (click)="go('/about')">Команда</span></h5>
        <h5><span [ngClass]="{'active':router.url=='/about/jobs'}" (click)="go('/about/jobs')">Вакансии</span></h5>
        <!-- <h5><span [ngClass]="{'active':router.url=='/contacts'}" (click)="go('/contacts')">Контакты</span></h5> -->
      </div>
      <div class="col-sm-4">
        
        <h4><span>Услуги</span></h4>
        <h5>Создание сайтов</h5>
        <h5>Создание лендингов</h5>
        <h5>Графический дизайн</h5>
        <h5>SEO-продвижение</h5>
        <h5>Контекстная реклама</h5>
        <h5>Ведение сайтов</h5>
        <h5>Создание интернет магазинов</h5>
      </div>
      <div class="col-sm-4">
        <h4><span [ngClass]="{'active':router.url=='/clients'}" (click)="go('/clients')">Портфолио</span></h4>
        <h4><span [ngClass]="{'active':router.url=='/prices'}" (click)="go('/prices')">Цены</span></h4>
        <h4><span [ngClass]="{'active':router.url=='/sale-sets'}" (click)="go('/sale-sets')">Пакеты услуг</span></h4>
      </div>
    </div>
    <div class="d-flex justify-content-around align-items-center end">
      <span>© «Progoff» 2018-{{CurrentYear}}. Все права защищены.</span>
      <span>Техподдержка <a href="tel:+79151999845">+7 (915) 199-98-45</a></span>
    </div>
  </div>
</div>
