<h2 *ngIf="sales && sales.length > 0">Пакеты услуг</h2>
<div *ngIf="sales && sales.length > 0">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol *ngIf="sales.length > 1" class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        *ngFor="let s of sales; let i = index"
        [attr.data-slide-to]="i"
        [ngClass]="{ active: i == 0 }"
      ></li>
    </ol>
    <!-- <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          </ol> -->
    <div class="carousel-inner my-4 pb-3">
      <div
        class="carousel-item"
        [ngClass]="{ active: i == 0 }"
        *ngFor="let s of sales; let i = index"
      >
        <div
        itemscope itemtype="https://schema.org/Product"
          class="d-flex flex-wrap align-items-stretch justify-content-center h-100"
        >
          <div class="d-flex align-items-center" style="width: 400px;">
            <img itemprop="logo" src="{{ s.Image }}" style="width: 100%;" />
          </div>
          <div
            class="d-flex flex-column justify-content-between align-items-start ml-4 body"
          >
            <div style="color: white;">
              <p itemprop="name" class="sale-head">{{ s.Name }}</p>
              <p itemprop="description" class="sale-sm-head">{{ s.Description }}</p>
            </div>
            <ul itemprop="description">
              <li *ngFor="let service of s.Services">{{ service.Name }}</li>
            </ul>
            <button routerLink="/sale-sets" class="btn btn-primary">
              Подробнее
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      *ngIf="sales.length > 1"
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-prev">
        <svg viewBox="0 0 20 100" width="20">
          <polyline
            points="20,0 0,50 20,100"
            stroke="white"
            stroke-linejoin="round"
            stroke-width="2"
            fill="none"
          />
        </svg>
      </span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      *ngIf="sales && sales.length > 1"
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-next">
        <svg viewBox="0 0 20 100" width="20">
          <polyline
            points="0,0 20,50 0,100"
            stroke="white"
            stroke-linejoin="round"
            stroke-width="2"
            fill="none"
          />
        </svg>
      </span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
