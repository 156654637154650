<ng-template class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" #modal>

  <div class="p-3">
    <mate-info *ngIf="ms.type=='mate'" [mate]="ms.attr"></mate-info>
    <application-form *ngIf="ms.type=='app'" [attachment]="ms.attr"></application-form>
  </div>
  
  

</ng-template>
