<div *ngIf="sales && sales.length>0" class="container">
  <div class="head w-75 w-sm-50">
    <h2>Пакеты услуг</h2>
    <p>Progoff найдет подход к любому клиенту. Для тех, кто хочет много и недорого, мы готовы предложить уже сформированные пакеты по сниженным ценам. Если Вы не нашли среди наших услуг то, что Вас интересует, напишите нам, и мы с радостью предложим несколько оригинальных решений.</p>
    
  </div>
 
  <div class="main-container">
    <div itemscope itemtype="http://schema.org/Offer" class="card" *ngFor="let s of sales">
      <img itemprop="image" src="{{s.Image}}" class="card-img-top" [alt]="s.Name">
      <div class="card-body">
        <h5 itemprop="name" class="card-title">{{s.Name}}</h5>
        <p itemprop="description" class="card-text">{{s.Description}}</p>
        <h5>В пакет входят услуги:</h5>
        <ul>
          <li itemprop="itemOffered" *ngFor="let service of s.Services">{{service.Name}}</li>
        </ul>
        <div class="d-flex align-items-center position-relative" style="overflow:hidden">
            <span itemprop="price" class="price">Цена: {{getPrice(s)}}₽</span>
            <a  class="btn btn-prog-light" (click)="ms.open('app', getAttachment(s))">Подать заявку</a>
            
        </div>
        
      </div>
    </div>
  </div>
  
</div>
