<form [formGroup]="userForm">
    <h3 class="d-flex justify-content-between align-items-center" style="font-weight:400">Отправление заявки<i *ngIf="show" class="fas fa-times" style="font-size: 1.5rem;" (click)="ms.close()"></i></h3>
    <div class="form-group">
      <label for="na" [ngClass]="{'text-danger':(submitted && f.Name.errors)}">Ваше имя</label>
      <input [ngClass]="{'border-danger':(submitted && f.Name.errors)}" formControlName="Name" class="form-control" id="na" placeholder="Ваше имя">
      <small  class="form-text text-danger" *ngIf="submitted && f.Name.errors && f.Name.errors.required">Введите Ваше имя</small>
    </div>
    <div class="form-group">
        <label for="exampleInputEmail1" [ngClass]="{'text-danger':(submitted && f.Email.errors)}">Email</label>
        <input [ngClass]="{'border-danger':(submitted && f.Email.errors)}" formControlName="Email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ваш email">
        <small  class="form-text text-danger" *ngIf="submitted && f.Email.errors && f.Email.errors.required">Введите email</small>
        <small id="emailHelp1" class="form-text text-danger" *ngIf="submitted && f.Email.errors && f.Email.errors.email">Некорректный email</small>
    </div>
    <!-- <div class="form-group">
        <label for="exampleInputPhone1" [ngClass]="{'text-danger':(submitted && f.Phone.errors)}">Телефон</label>
        <input [ngClass]="{'border-danger':(submitted && f.Phone.errors)}" formControlName="Phone" type="tel" pattern="\d[0-9]{5,}" class="form-control" id="exampleInputPhone1"  placeholder="Ваш  телефон">
        <small  class="form-text text-danger" *ngIf="submitted && f.Phone.errors && f.Phone.errors.required">Введите телефон</small>
        <small  class="form-text text-danger" *ngIf="submitted && f.Phone.errors && f.Phone.errors.pattern">Некорректный телефон</small>
    </div> -->
    <div class="form-group">
        <label for="DES">Описание</label>
        <textarea formControlName="Description" class="form-control" rows="5" id="DES" placeholder="Описание"></textarea>
    </div>
    <div class="attachments main-container" *ngIf="attachment">
        <div class="attachment">
            <img src="{{attachment.Content.Image}}">
            <div class="d-flex flex-column ml-2">
                <span>{{attachment.Content.Name}}</span>
                <span>{{attachment.Type=='sale-set'?'набор услуг':'вакансия'}}</span>
            </div>
        </div>
    </div>
    <div class="form-group form-check">
        <input formControlName="Privacy" type="checkbox" class="form-check-input" id="exampleCheck1" [ngClass]="{'border-danger':(submitted && f.Privacy.errors)}">
        <label class="form-check-label" for="exampleCheck1">С <a routerLink="/policy" (click)="ms.close()">политикой конфиденциальности</a> ознакомлен</label>
        <small class="form-text text-danger" *ngIf="submitted && f.Privacy.errors">Вы должны согласиться с политикой конфиденциальности</small>
    </div>
    <button class="btn btn-prog" (click)="send()">Отправить</button>
  </form>
  