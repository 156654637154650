<div class=" d-flex justify-content-center">
<div class="mt-5 m-sm-5 p-3 text-white border border-white rounded container">
  <h5>1. Основные понятия</h5>
  <ul>
  <li>1.1. Сайт – сайт, расположенный в сети Интернет по адресу http://progoff.com/.</li>
  <li>1.2. Разработчик – физическое или юридическое лицо, размещающее приложение на Сайте и использующее его в соответствии с Условиями размещения Сайта и Правилами размещения Сайта на Сайте. Фамилия, имя и отчество либо наименование Разработчика, а также иная информация о нем указаны в разделе «Информация о разработчике» в окне запуска Сайта и в настройках Сайта.</li>
  <li>1.3. Администрация Сайта – общество с ограниченной ответственностью «progoff».</li>
  <li>1.4. Пользователь – Пользователь Сайта, зарегистрированный в установленном порядке и использующий Сайт.</li>
  <li>1.5. Политика – настоящая Типовая политика конфиденциальности разработчиков Сайта для Сайта.</li>
  </ul>

  <br>

  <h5>2. Общие положения</h5>
  <ul>
  <li>2.1. Настоящая Политика является официальным типовым документом Разработчиков, и определяет порядок обработки и защиты информации о физических лицах, использующих Сайт. Настоящая Политика не распространяет свое действие на Разработчиков, которые опубликовали и применяют свой собственный документ, определяющий политику в отношении обработки персональных данных Пользователей.</li>
  <li>2.2. Целью настоящей Политики является обеспечение надлежащей защиты информации о Пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.</li>
  <li>2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях, регулируются настоящей Политикой, иными официальными документами Разработчиков и действующим российским законодательством.</li>
  <li>2.4. Действующая редакция Политики, являющейся публичным документом, разработана Администрацией Сайта и доступна любому Пользователю сети Интернет при переходе по гипертекстовой ссылке «Политика конфиденциальности разработчика Сайта». Администрация Сайта вправе вносить изменения в настоящую Политику. При внесении изменений в Политику Администрация Сайта размещает новую редакцию Политики на Сайте по постоянному адресу https://progoff.com/uprivacy. Администрация Сайта рекомендует Пользователям регулярно проверять условия настоящих Правил на предмет их изменения и/или дополнения. Продолжение использования Сайта Пользователем после внесения изменений и/или дополнений в настоящие Правила означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</li>
  <li>2.5. Настоящая Политика разработана и используется в соответствии с Правилами пользования сайтом progoff, размещенными на Сайте по адресу http://progoff.com/terms, а также Правилами защиты информации о Пользователях сайта progoff, размещенными на Сайте по адресу http://progoff.com/privacy.</li>
  <li>2.6. Используя Сайт, Пользователь выражает свое согласие с условиями настоящей Политики.</li>
  <li>2.7. В случае несогласия Пользователя с условиями настоящей Политики использование Сайта должно быть немедленно прекращено.</li>
  </ul>

  <br>

  <h5>3. Условия пользования Сайта</h5>
  <ul>
  <li>3.1. Оказывая услуги по использованию Сайта, Разработчик, действуя разумно и добросовестно, считает, что Пользователь:</li>
    <ul>
      <li>обладает всеми необходимыми правами, позволяющими ему осуществлять запуск и использование Сайта;</li>
      <li>указывает достоверную информацию о себе в объемах, необходимых для пользования Сайта;</li>
      <li>осознает, что информация, размещаемая в результате использования Пользователем Сайта, может становиться доступной для других Пользователей Сайта, может быть скопирована и распространена такими Пользователями;</li>
      <li>осознает, что некоторые виды информации, переданные им другим Пользователям при использовании Сайта, не могут быть удалены самим Пользователем;</li>
      <li>ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права и обязанности.</li>
    </ul>
  <li>3.2. Разработчик не проверяет достоверность получаемой (собираемой) информации о Пользователях.</li>
  </ul>

  <br>

  <h5>4. Цели обработки информации</h5>
  Разработчик осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях выполнения обязательств Разработчика перед Пользователями в отношении использования Сайта.<br>
  
  <br>
  
  <h5>5. Состав информации о Пользователях</h5>
  Разработчик обрабатывает информацию о Пользователях, которая включает в себя:
  <ul>
    <li>5.1. Персональные данные Пользователей, предоставляемые Администрацией Сайта с согласия Пользователей и необходимые для использования Сайта: имя, фамилия, дата рождения, профильная фотография.</li>
    <li>5.2. Информация, предоставляемая Пользователями при использовании Сайта (например, никнэйм);</li>
    <li>5.3. Информация, которая может быть получена Разработчиком в результате действий Пользователей при использовании Сайта (IP-адреса, cookies, статистика использования Сайта и т.д.).</li>
  </ul>

  <br>

  <h5>6. Обработка персональных данных Пользователей</h5>
  <ul>
  <li>6.1. Обработка персональных данных осуществляется на основе принципов:</li>
    <ul>
      <li>законности целей и способов обработки персональных данных и добросовестности;</li>
      <li>соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Разработчиков;</li>
      <li>соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;</li>
      <li>недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих персональные данные.</li>
    </ul>
      6.1.1. Условия и цели обработки персональных и иных данных<br>
      Разработчик осуществляет обработку персональных и иных данных Пользователя с его согласия, предусмотренного п. 6.1.2 настоящей Политики, в целях оказания услуг Пользователю по использованию Сайта.<br>
      6.1.2. Сбор персональных и иных данных<br>
      Персональные данные Пользователя и информация, предусмотренные пунктами 5.1, 5.2 настоящей Политики, передаются Разработчику Администрацией Сайта с согласия Пользователя, предоставляемого в форме конклюдентного действия при первом запуске и/или использовании Сайта (в частности, при нажатии соответствующей кнопки (при ее наличии) при первом запуске и/или использовании Сайта). Информация, предусмотренная п. 5.3 настоящей Политики, собираются Разработчиком без участия Администрацией Сайта с согласия Пользователя, предоставляемого в форме конклюдентного действия при первом запуске и/или использовании Пользователем Сайта (в частности, при нажатии соответствующей кнопки (при ее наличии) при первом запуске и/или использовании Сайта).<br>
      6.1.3. Передача персональных данных<br>
      Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой. Предоставление персональных данных Пользователей по запросу государственных органов (органов местного самоуправления) осуществляется в порядке, предусмотренном законодательством.<br>
  <li>6.2. Хранение персональных и иных необходимых данных Пользователя осуществляется в течение срока использования Сайта, а после прекращения использования Сайта – в течение необходимого и установленного действующим законодательством Российской Федерации срока.</li>
  </ul>
  
  <br>

  <h5>7. Права и обязанности Пользователей</h5>
  <ul>
    <li>7.1. Пользователи вправе:</li>
    7.1.1. осуществлять свободный бесплатный доступ к информации о себе посредством использования Сайта;<br>
    7.1.2. на основании запроса получать от Разработчика информацию, касающуюся обработки его персональных данных.<br>
  </ul>

  <br>

  <h5>8. Меры по защите информации о Пользователях</h5>
  <p>Разработчик принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий. Описание соответствующих технических и организационно-правовых мер содержится в локальных нормативных актах Разработчика.</p>
  
  <br>

  <h5>9. Ограничение действия Политики</h5>
  <p>Действие настоящей Политики не распространяется на действия и интернет-ресурсы третьих лиц. 
  Разработчик не несет ответственности за действия третьих лиц, получивших в результате использования Сайта доступ к информации о Пользователе, которая, в силу природы Сайта, доступна любому Пользователю сети Интернет. Разработчик рекомендует Пользователям ответственно подходить к решению вопроса об объеме информации о себе, размещаемой на Сайте.</p>
  
  <br>

  <h5>10. Обращения Пользователей</h5>
  <ul>
    <li>10.1. Пользователи вправе направлять Разработчику свои запросы, в том числе запросы относительно использования их персональных данных, предусмотренные п. 7.1.2 настоящей Политики, в письменной форме по адресу, указанному в разделе «Настройки» Сайта, или в форме электронного документа, подписанного квалифицированной электронной подписью в соответствии с законодательством Российской Федерации, по адресу электронной почты, указанному в разделе «Настройки» Сайта.</li>
    <li>10.2. Запрос, направляемый Пользователем, должен содержать следующую информацию:</li>
      – номер основного документа, удостоверяющего личность Пользователя или его представителя;<br>
      – сведения о дате выдачи указанного документа и выдавшем его органе;<br>
      – сведения, подтверждающие участие Пользователя в отношениях с Разработчиком (в частности, сведения о загрузке Сайта на персональную страницу, никнейм, используемый Пользователем при использовании Сайта);<br>
      – подпись Пользователя или его представителя.<br>
    <li>10.3. Разработчик обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 30 дней с момента поступления обращения.</li>
    <li>10.4. Вся корреспонденция, полученная Разработчиком от Пользователей (обращения в письменной или электронной форме), относится к информации ограниченного доступа и не разглашается без письменного согласия Пользователя. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.</li>
  </ul>

</div>
</div>