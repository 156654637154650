<div [ngClass]="{'close-menu':!show}">
  <div class="side-menu" >
    <main-menu ></main-menu>
    <div (click)="showMenu()" class="show-menu" [ngClass]="{'close-btn':show}">
      <div>
        <span style="height: 4px;"></span>
        <span style="height: 4px;"></span>
        <span style="height: 4px;"></span>
      </div>
      
    </div>
  </div>
  <div class="d-flex flex-column mmain" (click)="hide()">
    <div style="flex: auto" style="min-height:100vh">
      <router-outlet></router-outlet>
    </div>
    <div style="flex-shrink: 0">
      <app-footer></app-footer>
    </div>
  </div>

</div>

<app-modal></app-modal>
<app-load></app-load>


