<div class="container container-fluid">
  <div class="head w-75 w-sm-50">
    <h2>Макеты сайтов</h2>
    <p>Мы понимаем, как порой сложно определиться с макетом будущего сайта. Возможности нашей команды в плане верстки сайтов безграничны, поэтому предлагаем вам подборку готовых макетов для вдохновения. Быть может один из них послужит основой для вашего нового шедевра.</p>
  </div>
  <div class="mate" routerLink="/example/{{m.Id}}"  [ngClass]="{'active':showp}" *ngFor="let m of makets">
    <img src="{{m.Path}}">
  </div>
  <div class="head w-75 w-sm-50">
    <p>Макеты и шаблоны найдены в сети Интернет, как свободнодоступные. Если вы автор макета или шаблона и против его распостранения, просим уведомить нас для устранения правонарушения</p>
    <button class="btn btn-rprog" (click)="ms.open('app')">Уведомить</button>
  </div>

</div>