
<h2>Как мы работаем</h2>
<div class="process container">
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[0]+'px'}">         
        <div class="back"></div>       
      </div>
      <img src="../../assets/images/process/evaluation.png">
    </div>
    <span [ngStyle]="{'height':sizes[1]+'px'}"></span>
    <div class="process-content" [ngClass]="{'active':sizes[0]>0}">
      <h4>Изучаем бизнес</h4>
      <p>Мы исследуем все тонкости Вашего бизнеса и варианты его продвижения в сети. На основе полученных результатов подбираем для Вас наилучшую стратегию развития проекта.</p>
    </div>
  </div>
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[2]+'px'}">         
        <div class="back"></div>       
      </div>
      
      <img src="../../assets/images/process/meeting.png">
    </div>
    <span [ngStyle]="{'height':sizes[3]+'px'}"></span>
    <div class="process-content" [ngClass]="{'active':sizes[2]>0}">
      <h4>Обсуждаем требования</h4>
      <p>На основе данных, полученных в ходе первичного анализа, мы согласуем с заказчиком техническое задание и сроки выполнения проекта.</p>
    </div>
  </div>
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[4]+'px'}">        
         <div class="back"></div>      
        </div>
      <img src="../../assets/images/process/creativity.png">
    </div>
    <span [ngStyle]="{'height':sizes[5]+'px'}"></span>
    <div class="process-content" [ngClass]="{'active':sizes[4]>0}">
      <h4>Создаем дизайн</h4>
      <p>Наши дизайнеры подбирают для Вашего проекта наилучшее графическое оформление.</p>
    </div>
  </div>
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[6]+'px'}">         
        <div class="back"></div>       
      </div>
      <img src="../../assets/images/process/data.png">
    </div>
    <span [ngStyle]="{'height':sizes[7]+'px'}"></span>
    <div class="process-content" [ngClass]="{'active':sizes[6]>0}">
      <h4>Создаем сайт</h4>
      <p>Наши программисты создают структуру Вашего проекта. На этом этапе реализуется весь необходимый функционал сайта.</p>
    </div>
  </div>
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[8]+'px'}">         
        <div class="back"></div>       
      </div>
      <img src="../../assets/images/process/responsive.png">
    </div>
    <span [ngStyle]="{'height':sizes[9]+'px'}"></span>
    <div class="process-content" [ngClass]="{'active':sizes[8]>0}">
      <h4>Наполняем контентом</h4>
      <p>Наши контент-менеджеры, согласно техническому заданию, вносят все необходимые данные на сайт. Проект практически готов.</p>
    </div>
  </div>
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[10]+'px'}">         
        <div class="back"></div>       
      </div>
      <img src="../../assets/images/process/speed-test.png">
    </div>
    <span [ngStyle]="{'height':sizes[11]+'px'}"></span>
    <div class="process-content" [ngClass]="{'active':sizes[10]>0}">
      <h4>Отладка проекта</h4>
      <p>Наша команда тестировщиков тщательно проверяет весь сайт на предмет ошибок, выявляет недочеты и отправляет его на доработку.</p>
    </div>
  </div>
  <div class="stage">
    <div class="image">
      <div class="back-container" [ngStyle]="{'height':sizes[12]+'px'}">         
        <div class="back"></div>       
      </div>
      <img src="../../assets/images/process/network.png">
    </div>
    <div class="process-content" [ngClass]="{'active':sizes[12]>0}">
      <h4>Дарим жизнь</h4>
      <p>Ваш проект готов! Мы выгружаем его на хост, вносим все финальные корректировки и запускаем сайт в работу.</p>
    </div>
  </div>
</div>
