<div class="container-fluid">
  <div class="mate" [ngClass]="{'active':showp}" *ngFor="let m of team">
    <div itemscope itemtype="http://schema.org/Person">
      <h4 itemprop="name">{{m.Name}}</h4>
      <small itemprop="jobTitle">{{m.Position}}</small>
      <div>
        <a *ngIf="m.VK" class="p-link" href="{{m.VK}}" target="blank"><i class="fab fa-vk"></i></a>
        <a *ngIf="m.Instagram"  class="p-link" href="{{m.Instagram}}" target="blank"><i class="fab fa-instagram"></i></a>
      </div>
    </div>
    
    <button class="btn btn-prog" (click)="show(m)">Подробнее</button>
  </div>

</div>