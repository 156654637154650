<div class="container">
  <div class="head w-75 w-sm-50">
    <h2>О компании</h2>
    <p>Progoff знает толк в своем деле. Наша команда квалифицированных специалистов заинтересованна в качестванном и быстром продвижении Вашего бизнеса. Мы используем только передовые технологии, поэтому Вашим клиентам будет максимально комфортно общаться с сайтом, а Вам, в свою очередь, управлять бизнесом.</p>
    <button class="btn btn-rprog" (click)="ms.open('app')">Обратная связь</button>
  </div>
  <div class="mx-sm-5">
    <about-body></about-body>
  </div>
</div>

