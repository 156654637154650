<div class="d-flex flex-column justify-content-between main" style="height: 100vh;">
  <div class="logo" >
    <!-- <h2 (click)="go('/')">Progoff</h2> -->
    <img src="../../assets/images/progoff.png" (click)="go('/')" alt="Progoff">
    <p>Компания разработки сайтов</p>
  </div>
  <div class="menu" >
    <div class="menu-item" [ngClass]="{'active':router.url=='/about'}" (click)="go('/about')">
      <span>О компании</span>
    </div>
    <div class="menu-item" [ngClass]="{'active':router.url=='/examples'}" (click)="go('/examples')">
      <span>Макеты сайтов</span>
    </div>
    <div class="menu-item" [ngClass]="{'active':router.url=='/clients'}" (click)="go('/clients')">
      <span>Портфолио</span>
    </div>
    <div class="menu-item" [ngClass]="{'active':router.url=='/prices'}" (click)="go('/prices')">
      <span>Цены</span>
    </div>
    <div class="menu-item" [ngClass]="{'active':router.url=='/sale-sets'}" (click)="go('/sale-sets')">
      <span>Пакеты услуг</span>
    </div>
    <!-- <div class="menu-item" [ngClass]="{'active':router.url=='/contacts'}" (click)="go('/contacts')">
      <span>Контакты</span>
    </div> -->
    <div class="social-nets">
      <a href="http://vk.com/progoff" target="blank"><i class="fab fa-vk"></i></a>
      <a href="https://www.instagram.com/progoff.ru" target="blank"><i class="fab fa-instagram"></i></a>
    </div>
  </div>
  <div class="footer">
    <a href="tel:+79670654854">+7 (967) 065-48-54</a>
    <button class="btn w-100" (click)="ms.open('app')">Подать заявку</button>
  </div>
</div>
