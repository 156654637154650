<div class="container">
    <div class="head w-75 w-sm-50">
      <h2>Цены разработки</h2>
      <p>Мы не пользуемся конструкторами сайтов, у нас нет шаблонов и готовых макетов. Мы ищем индивидуальный подход к каждому клиенту. Конечная стоимость проекта зависит лишь от желаний конкретного заказчика.</p>
      
    </div>
    <div class="prices" *ngIf="prices">
      <div itemscope itemtype="http://schema.org/Offer" class="price" *ngFor="let p of prices; let i = index">
        <div class="image">
          <img itemprop="image" src="{{p.Image}}" [ngClass]="{'active': show[i]}">
        </div>
        <div class="values" [ngClass]="{'active': show[i]}">
          <h3 itemprop="category">{{p.Name}}</h3>
          <span *ngFor="let s of p.Services"><p class="d-inline m-0" itemprop="name">{{s.Name}}</p><br><b>от <p class="d-inline m-0" itemprop="price">{{s.Price}} тыс. рублей</p></b></span>
        </div>
      </div>
    </div>
    <div class="head w-75 w-sm-50">
      <p>Ищите более выгодное предложение? У нас есть пакеты услуг по сниженным ценам, в которых сформированны наиболее популярные конфигурации проектов в современной сфере сайтостроения.</p>
      <button class="btn btn-rprog" routerLink="/sale-sets">Перейти</button>
    </div>
  </div>